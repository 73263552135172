import request from '@/utils/request'

// 获取知识讲堂资讯
export function WeGetGoodNewsPage(query) {
    return request({
        url: 'Knowledge/WeGetGoodNewsPage',
        method: 'get',
        params: query
    })
}
// 获取知识讲堂资讯
export function WeGetGoodNews(query) {
    return request({
        url: 'Knowledge/WeGetGoodNews',
        method: 'get',
        params: query
    })
}
// 获取企业生产要素表数据,手机端使用，无需用户验证
export function WeGetEntElementPage(query) {
    return request({
        url: 'Enterprise/WeGetEntElementPage',
        method: 'get',
        params: query
    })
}
// 获取中介机构分页列表
export function WeGetAgencyPage(query) {
    return request({
        url: 'Agency/WeGetAgencyPage',
        method: 'get',
        params: query
    })
}
// 获取中介机构详情，手机端使用，无需进行用户验证GetInteractPage
export function WeGetAgency(query) {
    return request({
        url: 'Agency/WeGetAgency',
        method: 'get',
        params: query
    })
}
// 获取我提交的区企互动分页列表
export function GetInteractPage(query) {
    return request({
        url: 'Interact/GetInteractPage',
        method: 'get',
        params: query
    })
}
// 提交区企互动信息，无需用户验证
export function WeSubInteract(data) {
    return request({
        url: 'Interact/WeSubInteract',
        method: 'post',
        data: data
    })
}
// 评价中介机构
export function WeAssessAgency(data) {
    return request({
        url: 'Agency/WeAssessAgency',
        method: 'post',
        data: data
    })
}
// 企业注册，手机端使用
export function WeRegister(data) {
    return request({
        url: 'Enterprise/WeRegister',
        method: 'post',
        data: data
    })
}
// 获取资讯类型列表
export function WeGetGNTypeList(query) {
    return request({
        url: 'Knowledge/WeGetGNTypeList',
        method: 'get',
        params: query
    })
}
// 获取指定机构的服务评价分页列表，无需验证用户
export function WeGetAssessAgencyPage(query) {
    return request({
        url: 'Agency/WeGetAssessAgencyPage',
        method: 'get',
        params: query
    })
}
// 获取指定中介机构的服务分页列表，手机端使用，无需进行用户验证
export function WeGetAgencyServiceList(query) {
    return request({
        url: 'Agency/WeGetAgencyServiceList',
        method: 'get',
        params: query
    })
}
// 验证企业用户
export function CheckEntUser(data) {
    return request({
        url: 'Enterprise/CheckEntUser',
        method: 'post',
        data: data
    })
}
// 保存企业生产要素表数据
export function WeSaveEntElement(data) {
    return request({
        url: 'Enterprise/WeSaveEntElement',
        method: 'post',
        data: data
    })
}