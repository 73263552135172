<template>
  <div>
    <div>

    </div>
  </div>
</template>
<script>
import {GetInteractPage} from "@/api/Hyapi"
export default { 
  data() {
    return {
      getFrom:{
        openID:"djksvnjkdfnbkjdfnjbndfjkb",
        page:1,
        limit:999,
      }
    }
  },
  methods:{
    getInteractPage(){
      GetInteractPage(this.getFrom).then((res)=>{
        console.log(res);
      })
    },
  },
  mounted() {
    this.getInteractPage();
  },
}
</script>